<!-- Header -->
<template>
  <div
    class="head"
    :class="{
      headFix: isHeadFix,
    }"
  >
    <!-- mobile more -->
    <div
      class="phNavIcon"
      @click.stop="handlePhNavIconClick"
      :class="{ on: isNavShow }"
    ></div>

    <!-- logo -->
    <div class="logo">
      <a @click="$router.push('/portal')" class="pc-logo">
        <img src="../images/logo.png" alt="" />
      </a>
      <a @click="$router.push('/portal')" class="md-logo">
        <img v-if="isMobile" src="../images/logo_fo.png" alt="" />
        <img v-else src="../images/logo_fo.jpg" alt="" />
      </a>
    </div>

    <!-- nav -->
    <div
      class="nav"
      :style="{
        display: !isMobile || isNavShow ? 'block' : 'none',
      }"
    >
      <ul>
        <li
          v-for="(item, index) in nav"
          :key="index"
          class="on nav_list"
          @mouseover="handleMouseOver(index)"
          @mouseleave="handleMouseLeave(index)"
          @click.stop="handleNavClick(index)"
        >
          <span v-if="item.href">
            <a @click.stop="$router.push(item.href)">{{ item.title }}</a>
          </span>
          <span v-else> {{ item.title }} </span>

          <div
            class="nav_list"
            v-if="item.list.length"
            :style="{
              display: showNavList && currentIndex === index ? 'block' : 'none',
            }"
          >
            <p v-for="(subItem, subIndex) in item.list" :key="subIndex">
              <a @click.stop="$router.push(subItem.href)">{{ subItem.title }}</a>
            </p>
          </div>
        </li>
      </ul>
    </div>

    <!-- right aside -->
    <!-- <div class="head_other">
      <a class="search" href="index.php%EF%B9%96m=search.html"></a>
    </div> -->
  </div>
</template>

<script>
import portalApi from '../../../../lvya-common/src/api/eos/portal'
import { debounce } from '../../../../lvya-common/src/utils/DebounceThrottle.js'
import { mapState } from 'vuex'

export default {
  props: {},
  components: {},
  data() {
    return {
      activeIndex: this.$route.query.activeIndex
        ? this.$route.query.activeIndex
        : '1',
      info: '',
      navList: '',
      portalSchoolInfo: '',

      scrollTop: 0,
      pageWidth: 1080,
      showNavList: false,
      currentIndex: undefined,
      isNavShow: false,
    }
  },
  computed: {
    isShow() {
      return this.$route.path !== '/portalDetails'
    },
    ...mapState({
      storeNav: (state) => state.yzsyStore.nav,
    }),
    nav() {
      return this.storeNav.map(nav => {
        return {
          title: nav.title,
          href: nav.tzurl,
          list: nav?.stypes?.map(item => {
            return {
              title: item.title,
              href: item.tzurl,
              id: item.id
            } || []
          }) || []
        }
      })
    },
    isHeadFix() {
      if (this.scrollTop > 140) {
        return true
      }
      return false
    },
    isMobile() {
      return this.pageWidth < 1023
    },
  },
  watch: {
    $route(to, from) {
      this.activeIndex = this.$route.query.activeIndex
        ? this.$route.query.activeIndex
        : '1'
    },
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath)
      this.activeIndex = key
      // console.log(this.activeIndex, '1111')
    },
    async hrefListUrl(data) {
      console.log(data, '信息')
      let res = {
        noSchoolId: true,
        id: data.id,
        type: '3',
      }
      await portalApi.addCount(res)
      if (data.title == '首页') {
        this.$router.push({
          path: '/portal',
          query: { title: data.title, activeIndex: this.activeIndex },
        })
      } else if (data.tzurl != null && data.tzurl != '') {
        window.open(data.tzurl, '_blank')
      } else {
        this.$router.push({
          path: '/portalList',
          query: {
            id: data.id,
            pid: data.pid,
            title: data.title,
            activeIndex: this.activeIndex,
          },
        })
      }
    },
    // 基础设置
    async header() {
      let resData = await portalApi.getSetInfoBySid({
        id: '7',
      })
      this.info = resData
      // console.log(resData)
    },
    // 导航栏
    async getNavList() {
      let res = { id: '7', type: '1' }
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      if (userInfo) {
        res.roleTypes = userInfo.type
      } else {
        res.roleTypes = '3'
      }
      let resData = await portalApi.getNav(res)
      this.navList = resData
    },

    handleMouseOver(index) {
      if (this.isMobile) return
      this.currentIndex = index
      this.showNavList = true
    },
    handleMouseLeave() {
      if (this.isMobile) return
      this.currentIndex = undefined
      this.showNavList = false
    },
    handleNavClick(index) {
      if (!this.isMobile) return
      this.currentIndex = index
      this.showNavList = true
    },
    handlePhNavIconClick() {
      this.isNavShow = !this.isNavShow
    },
  },
  created() {
    this.portalSchoolInfo = JSON.parse(
      localStorage.getItem('portalSchoolInfo')
    )
  },
  async mounted() {
    this.scrollTop = document.documentElement.scrollTop
    window.addEventListener('scroll', (e) => {
      this.scrollTop = document.documentElement.scrollTop
    })

    this.pageWidth = document.documentElement.clientWidth
    window.addEventListener(
      'resize',
      debounce((e) => {
        this.pageWidth = document.documentElement.clientWidth
      }, 50)
    )
    document.body.addEventListener('click', () => {
      this.isNavShow = false
    })
  },
  beforeDestroy() {
    window.removeEventListener('scroll', () => {})
    window.removeEventListener('resize', () => {})
    document.body.removeEventListener('click', () => {
      this.currentIndex = undefined
      this.isNavShow = false
    })
  },
}
</script>

<style lang="less" scoped>
@import "../style/common.css";
</style>
